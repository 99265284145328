import React from "react";
import { MobileDownloadButton } from "./HomePage";
import AnimatedImage from "../components/AnimatedImage";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";
import { harshitLinkedin, mukulLinkedin } from "../utils/Constants";

// Team
export const TeamSection = () => {
  const isMobileDevice = useIsMobileDevice();

  const margin = isMobileDevice ? 0 : 60;
  const width = isMobileDevice ? "80%" : "50%";
  const photoDimension = isMobileDevice ? 250 : 300;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2>Founder's Note</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <AnimatedImage
            src={require("../images/Harshit.jpg")}
            style={{ width: photoDimension, height: photoDimension, borderRadius: 30 }}
          />
          <AnimatedImage
            src={require("../images/linkedin.jpg")}
            style={{ width: 60, height: 60, borderRadius: 15, marginTop: -30, cursor: "pointer" }}
            onClick={() => window.open(harshitLinkedin, "_blank")}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: margin,
            marginTop: 50,
          }}
        >
          <AnimatedImage
            src={require("../images/Mukul.png")}
            style={{ width: photoDimension, height: photoDimension, borderRadius: 30 }}
          />
          <AnimatedImage
            src={require("../images/linkedin.jpg")}
            style={{ width: 60, height: 60, borderRadius: 15, marginTop: -30, cursor: "pointer" }}
            onClick={() => window.open(mukulLinkedin, "_blank")}
          />
        </div>
      </div>
      <h4 style={{ width: width, marginTop: 50 }}>
        <br />
        We are Harshit Sharma and Mukul Rathee, the founders of Livorn, driven by a simple mission:
        Provide everyone an organized life. <br />
        <br />
        With our app, we aim to provide an all-in-one solution for all the tools required to help
        you grow in life, ensuring you stay on top of your schedule without the hassle of
        mismanagement.
        <br /> <br />
        Our vision is to empower you to achieve more by simplifying Habit creation, task management
        through multi-channel reminders & Mission to achieve your goals. Livorn keeps you organized
        and in control. <br />
        <br />
        Ready to take the first step towards a stress-free, productive life? Download the app and
        make it happen!
      </h4>

      <MobileDownloadButton/>
    </div>
  );
};

const TeamPage = () => {
  return (
    <div style={{ paddingTop: 80, paddingBottom: 100 }}>
      <TeamSection />
    </div>
  );
};

export default TeamPage;
