import React, { useRef, useState, useEffect } from "react";
import Section from "../components/Section";
import Colors from "../utils/Colors";
import { playStoreUrl, videoUrl } from "../utils/Constants";
import AppReviews from "../components/AppReviews";
// import PrimaryButton from "../components/PrimaryButton";
import AnimatedImage from "../components/AnimatedImage";
import AppScreenshots from "../components/AppScreenshots";
import { goToPlayStore } from "../functions/goToPlayStore";
import { useLocation, useNavigate } from "react-router-dom";
import { ContactSection, HelpSection } from "./ContactPage";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";
import landingBackground from "../images/landingBackground.webp";
import { TeamSection } from "./TeamPage";

// TODO => This file needs a lot of refracting

const appReviews = [
  require("../images/Reviews/1.jpg"),
  require("../images/Reviews/2.jpg"),
  require("../images/Reviews/3.jpg"),
  require("../images/Reviews/4.jpg"),
  require("../images/Reviews/5.jpg"),
  require("../images/Reviews/6.jpg"),
  require("../images/Reviews/7.jpg"),
  require("../images/Reviews/8.jpg"),
  require("../images/Reviews/9.jpg"),
  require("../images/Reviews/10.jpg"),
  require("../images/Reviews/11.jpg"),
  require("../images/Reviews/12.jpg"),
];

const missionScreenshots = [
  require("../images/Missions 1.png"),
  require("../images/Missions 2.png"),
];
const reminderScreenshots = [
  require("../images/Reminder 1.png"),
  require("../images/Reminder 2.png"),
];
const habitScreenshots = [require("../images/Habit 1.png"), require("../images/Habit 2.png")];
const plannerScreenshots = [require("../images/My Life 1.png"), require("../images/My Life 2.png")];
const inspireScreenshots = [require("../images/Inspire 1.png"), require("../images/Inspire 2.png")];

// const appScreenshots = [
//   require("../images/Missions 1.png"),
//   require("../images/Missions 2.png"),
//   require("../images/Inspire 1.png"),
//   require("../images/Inspire 2.png"),
//   require("../images/Manage Me.png"),
//   require("../images/Reminder.png"),
//   require("../images/My Life 1.png"),
//   require("../images/My Life 2.png"),
// ];

const PageTitle = ({ boxColor, title }) => {
  const isMobileDevice = useIsMobileDevice();
  const width = isMobileDevice ? "80%" : "30%";
  const height = isMobileDevice ? 70 : 90;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: 100,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
      }}
    >
      <div
        style={{
          width: width,
          height: height,
          borderRadius: 20,
          border: "3px solid",
          borderColor: boxColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3>{title}</h3>
      </div>
    </div>
  );
};

// Pinned Download Button in Mobile
export const MobileDownloadButton = () => {
  const isMobileDevice = useIsMobileDevice();
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleDownloadClick = () => {
    if (isIOS) {
      // Redirect to iOS App Store if on iOS device
      window.open("https://www.apple.com/in/", "_blank");
    } else {
      // Fallback to goToPlayStore for other devices
      goToPlayStore();
    }
  };


  if (isMobileDevice) {
    return (
      <div style={mobileDownloadContainer}>
        <div style={mobileDownloadButton} onClick={handleDownloadClick}>
          <h3 style={{ fontSize: "20px" }}>Download App to Grow</h3>
        </div>
      </div>
    );
  }
};

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reviewsRef = useRef(null);
  const benefitsRef = useRef(null);
  const isMobileDevice = useIsMobileDevice();

  // Handle Video Play and Pause
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle Initial Scrolling
  useEffect(() => {
    if (location.state?.scrollTo === "reviews" && reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
    }
    if (location.state?.scrollTo === "benefits" && reviewsRef.current) {
      benefitsRef.current.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, location.pathname, navigate]);

  // Star Icon
  const starIconStyles = {
    marginLeft: 5,
    width: isMobileDevice ? 30 : 38,
    height: isMobileDevice ? 30 : 38,
  };

  // Video Container
  const videoStylesContainer = {
    borderRadius: 25,
    maxHeight:"80vh",
    maxWidth:"80vw",
    cursor: "pointer",
    overflow:"hidden",
    aspectRatio: "9/16",
    position: "relative",
    backgroundColor: "#071434",
    marginTop: isMobileDevice ? 25 : 0,
    height: isMobileDevice ?  "auto" : 650,
    marginBottom: isMobileDevice ? 20 : 15,
  };

  // Video Styles
  const videoStyles = {
    height: "100%",
    width:  "100%", 
  };

  // Icon Container Styles
  // const playIconSide = isMobileDevice ? 60 : 80;
  // const playIconStyles = {
  //   zIndex: 10,
  //   display: "flex",
  //   alignItems: "center",
  //   position: "absolute",
  //   justifyContent: "center",
  //   width: playIconSide,
  //   height: playIconSide,
  //   borderRadius: playIconSide,
  //   left: `calc(50% - ${playIconSide / 2}px)`,
  //   backgroundColor: hexToRGBA(Colors.backgroundPrimary, 0.3),
  //   top: isMobileDevice ? `calc(50% - ${playIconSide / 2}px)` : "40%",
  // };

  return (
    <div style={{ paddingBottom: 100 }}>
      {/* Introduction */}
      <Section background={landingBackground}>
        {/* Laptop Introduction */}
        {!isMobileDevice && (
          <div style={topSectionContainer}>
            <div style={videoStylesContainer}>
              <video
                muted
                ref={videoRef}
                autoPlay={true}
                style={videoStyles}
                onClick={handlePlayPause}
                poster={require("../images/verticalVideoThumbnail.png")}
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
              {/* {!isPlaying && (
                <div style={playIconStyles}>
                  <img
                    alt="Play Icon"
                    onClick={handlePlayPause}
                    src={require("../images/PlayIcon.png")}
                    style={{
                      marginLeft: "5px",
                      width: isMobileDevice ? 20 : 25,
                      height: isMobileDevice ? 20 : 25,
                    }}
                  ></img>
                </div>
              )} */}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <AnimatedImage
                src={require("../assets/LivornIcon.png")}
                style={{
                  width: isMobileDevice ? 20 : 170,
                  height: isMobileDevice ? 20 : 170,
                  borderRadius: 20,
                  marginLeft: isMobileDevice ? 0 : 130,
                }}
              />
              <h2
                style={{
                  marginTop: isMobileDevice ? 40 : 30,
                  maxWidth: "90%",
                  textAlign: "left",
                  marginLeft: isMobileDevice ? 0 : 130,
                }}
              >
                Complete <br />
                Growth & Lifestyle <br /> App for Students
              </h2>
              <AnimatedImage
                src={require("../images/Google Play.png")}
                onClick={() => window.open(playStoreUrl, "_blank")}
                style={{
                  cursor: "pointer",
                  aspectRatio: "824/245",
                  width: isMobileDevice ? 20 : 300,
                  marginTop: isMobileDevice ? 0 : 50,
                  marginLeft: isMobileDevice ? 0 : 130,
                }}
              />
            </div>
          </div>
        )}

        {/* Mobile Introduction */}
        {isMobileDevice && (
          <div style={{ ...topSectionContainer, alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: isMobileDevice ? "center" : "flex-start",
              }}
            >
              <h2
                style={{
                  marginTop: isMobileDevice ? 10 : 30,
                  maxWidth: !isMobileDevice && "90%",
                  textAlign: isMobileDevice ? "center" : "left",
                  marginLeft: isMobileDevice ? 0 : 130,
                  fontSize: isMobileDevice && 35,
                }}
              >
                Complete <br />
                Growth & Lifestyle <br /> App for Students
              </h2>
              <div style={{ ...videoStylesContainer }}>
                <video
                  muted
                  ref={videoRef}
                  autoPlay={true}
                  style={videoStyles}
                  onClick={handlePlayPause}
                  poster={require("../images/verticalVideoThumbnail.png")}
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
                {/* {!isPlaying && (
                  <div style={playIconStyles}>
                    <img
                      alt="Play Icon"
                      onClick={handlePlayPause}
                      src={require("../images/PlayIcon.png")}
                      style={{
                        marginLeft: "5px",
                        width: isMobileDevice ? 20 : 25,
                        height: isMobileDevice ? 20 : 25,
                      }}
                    />
                  </div>
                )} */}
              </div>
              <AnimatedImage
                src={require("../images/Google Play.png")}
                onClick={() => window.open(playStoreUrl, "_blank")}
                style={{
                  aspectRatio: "824/245",
                  width: isMobileDevice ? 280 : 300,
                  margin: isMobileDevice ? "0 auto" : "50px auto 0",
                  cursor: "pointer",
                  marginBottom: isMobileDevice && 40,
                  marginTop: isMobileDevice && 30,
                }}
              />
            </div>
          </div>
        )}
      </Section>

      {/* Reviews */}
      <div ref={reviewsRef}></div>
      <Section>
        <h2>
          50+ 5
          <img alt="Star Icon" style={starIconStyles} src={require("../images/StarIcon.png")}></img>{" "}
          reviews
        </h2>
        <h4 style={{ color: Colors.disabledWhite, marginTop: -20, marginBottom: 50 }}>
          with avg rating 4.98
        </h4>
        <AppReviews imageUrls={appReviews} />
      </Section>

      {/* Benefits */}
      <div ref={benefitsRef}></div>
      <Section>
        <h2>Platform designed to</h2>
        <PageTitle title="Missions" boxColor={Colors.missionOrange} />
        <AppScreenshots imageUrls={missionScreenshots} />
        <PageTitle title="Reminders" boxColor={Colors.reminderColor} />
        <AppScreenshots imageUrls={reminderScreenshots} />
        <PageTitle title="Habit" boxColor={Colors.habitColor} />
        <AppScreenshots imageUrls={habitScreenshots} />
        <PageTitle title="Planner" boxColor={Colors.myLifeVoilet} />
        <AppScreenshots imageUrls={plannerScreenshots} />
        <PageTitle title="Inspire" boxColor={Colors.livornPrimary} />
        <AppScreenshots imageUrls={inspireScreenshots} />
      </Section>

      {/* Team */}
      <TeamSection />

      {/* Contact */}
      <ContactSection />
      <HelpSection />

      <MobileDownloadButton />
    </div>
  );
};

export default HomePage;

const topSectionContainer = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  minHeight: "calc(100vh - 80px)",
};

const mobileDownloadContainer = {
  bottom: 0,
  zIndex: 1000,
  width: "100%",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px 0px 12px 0px",
  backgroundColor: Colors.backgroundPrimary,
};

const mobileDownloadButton = {
  width: "90%",
  display: "flex",
  borderRadius: 40,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: Colors.livornPrimary,
};
